import { Button, Loading } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

// Custom ButtonSubmit to support error message

const ButtonSubmit = ({
  className, errorMessage, isDisabled, isLoading, label, onAway, onClick, onHover, showErrorMessage, submitError,
}) => (
  <article className={className}>
    {isLoading
      ? <Loading />
      : (
        <>
          <Button
            className="ncss-btn-primary-dark bg-accent mt10-sm border"
            isDisabled={isDisabled}
            style={{ flexDirection: 'row', outline: 'none', verticalAlign: 'middle' }}
            onClick={onClick}
            onMouseLeave={onAway}
            onMouseOver={onHover}
          >
            {label}
          </Button>
          {isDisabled && showErrorMessage && <p className="text-color-error body-4 mt2-sm">{errorMessage}</p>}
          {submitError && <p className="text-color-error body-4 mt2-sm">{submitError}</p>}
        </>
      )}
  </article>
);

ButtonSubmit.defaultProps = {
  className: 'ncss-col-sm-12 va-sm-t',
  isDisabled: false,
  isLoading: false,
  label: 'Submit',
  onAway: () => {},
  onHover: () => {},
  showErrorMessage: true,
  submitError: '',
};

ButtonSubmit.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  onAway: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  onHover: PropTypes.func,
  showErrorMessage: PropTypes.bool,
  submitError: PropTypes.string,
};

export default ButtonSubmit;
