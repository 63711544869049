import { Device } from '@nike/nike-design-system-icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
  CCM,
  DataAnalyzer,
  DCN,
  Waitlines,
  Home,
  O2O,
  Patch,
  POSReasons,
  PrinterPortal,
  SCS,
  SLS,
  StoreHierarchy,
  StoreServices,
  TaxIdentifiers,
  Traffic,
  Zones,
} from '../assets/icons';

import './sidebar.css';

const NAV_ITEM_HEIGHT = 48;

const pages = {

ccm: { name: 'CCM', url: '/commonconfigmanager/config' },

  // eslint-disable-next-line sort-keys
dataAnalyzer: [
    // { name: 'Directive Analyzer', url: '/directiveanalyzer' },
    { name: 'Product Analyzer', url: '/productanalyzer' },
    { name: 'SNI Transaction Search ', url: '/snitransactionsearch' },
    { name: 'Transaction Analyzer', url: '/transactionanalyzer' },
  ],
  dcn: [
    { name: 'DCN Audit', url: '/dcnaudit' },
    { name: 'Generate Price DCN', url: '/generatepricedcn' },
    { name: 'Generate Product DCN', url: '/generateproductdcn' },
  ],
  home: { name: 'Home', url: '/home' },
  o2o: [
    { name: 'O2O Capabilities', url: '/onlinetooffline' },
    { name: 'O2O Order Lookup', url: '/onlinetooffline/orderlookup' },
    { name: 'QR Code Generator', url: '/qrcodegenerator' },
  ],
  patch: { name: 'Patch', url: '/patch' },
  posReasons: { name: 'POS Reasons', url: '/posreasons' },
  printerPortal: { name: 'Printer Portal', url: '/printerportal' },
  rlt: { name: 'Register Level Tracking', url: '/registerleveltracking' },
  scs: [
    { name: 'Manage Store Config', url: '/storeconfigservice/managestoreconfig' },
    { name: 'Search Config by Key', url: '/storeconfigservice/searchconfigbykey' },
    { name: 'SCS Bulk Edit', url: '/storeconfigservice/bulkedit' },
  ],
  sls: { name: 'SLS', url: '/storelocationservices/stores', urlPrefix: '/storelocationservices' },
  storeHierarchy: { name: 'Store Hierarchy', url: '/storehierarchy' },
  storeServices: { name: 'Store Services', url: '/storeservices' },
  taxIdentifiers: { name: 'Tax Identifiers', url: '/taxidentifiers' },
  traffic: { name: 'Traffic', url: '/traffic' },
  waitlines: { name: 'Waitlines', url: '/waitlines' },
  zones: { name: 'Zones', url: '/zones' },
};

const Nav = ({
  icon, isSubNav, page, pathname,
}) => (
  <article key={page.url} className={!isSubNav ? 'sidebar-item' : ''}>
    <a href={page.url} style={{ color: (pathname === page.url || pathname.startsWith(page.urlPrefix)) && 'black' }}>
      {icon}<p className="label">{page.name}</p>
    </a>
  </article>
);

Nav.defaultProps = {
  isSubNav: false,
};

Nav.propTypes = {
  icon: PropTypes.element.isRequired,
  isSubNav: PropTypes.bool,
  page: PropTypes.shape({ name: PropTypes.string.isRequired, url: PropTypes.string.isRequired, urlPrefix: PropTypes.string }).isRequired,
  pathname: PropTypes.string.isRequired,
};

const NavGroup = ({
  icon, isOpen, label, pathname, plusMinusMarginLeft, setIsOpen, subNavs,
}) => (
  <article
    className="sidebar-item"
    style={{ color: subNavs.map((nav) => nav.url).includes(pathname) && 'black', height: (1 + (isOpen ? subNavs.length : 0)) * NAV_ITEM_HEIGHT }}
    onFocus={() => setIsOpen(true)}
    onMouseLeave={() => setIsOpen(false)}
    onMouseOver={() => setIsOpen(true)}
  >
    {icon}<p className="label">{label}</p><i className={`label g72-${isOpen ? 'minus' : 'plus'}`} style={{ marginLeft: plusMinusMarginLeft }} />
    <section className="subnav">
      {subNavs.map((page) => <Nav key={page.url} isSubNav icon={icon} page={page} pathname={pathname} />)}
    </section>
  </article>
);

NavGroup.propTypes = {
  icon: PropTypes.element.isRequired,
  isOpen: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  plusMinusMarginLeft: PropTypes.string.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  subNavs: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired, url: PropTypes.string.isRequired, urlPrefix: PropTypes.string })).isRequired,
};

const SideBar = ({ location }) => {
  const [isDataAnalyzerOpen, setDataAnalyzerOpen] = useState(false);
  const [isDCNOpen, setDCNOpen] = useState(false);
  const [isO2OOpen, setO2OOpen] = useState(false);
  const [isSCSOpen, setSCSOpen] = useState(false);

  return (
    <nav className="sidebar">
      <Nav icon={<Home />} page={pages.home} pathname={location.pathname} />
      <NavGroup
        icon={<DataAnalyzer />}
        isOpen={isDataAnalyzerOpen}
        label="Data Analyzer"
        pathname={location.pathname}
        plusMinusMarginLeft="90px"
        setIsOpen={setDataAnalyzerOpen}
        subNavs={pages.dataAnalyzer}
      />
      <NavGroup
        icon={<DCN />}
        isOpen={isDCNOpen}
        label="DCN"
        pathname={location.pathname}
        plusMinusMarginLeft="155px"
        setIsOpen={setDCNOpen}
        subNavs={pages.dcn}
      />
      <Nav icon={<Waitlines />} page={pages.waitlines} pathname={location.pathname} />
      <NavGroup
        icon={<O2O />}
        isOpen={isO2OOpen}
        label="Online to Offline"
        pathname={location.pathname}
        plusMinusMarginLeft="73px"
        setIsOpen={setO2OOpen}
        subNavs={pages.o2o}
      />
      <Nav icon={<Patch />} page={pages.patch} pathname={location.pathname} />
      <Nav icon={<PrinterPortal />} page={pages.printerPortal} pathname={location.pathname} />
      <Nav icon={<POSReasons />} page={pages.posReasons} pathname={location.pathname} />
      <Nav icon={<Device />} page={pages.rlt} pathname={location.pathname} />
      <NavGroup
        icon={<SCS />}
        isOpen={isSCSOpen}
        label="SCS"
        pathname={location.pathname}
        plusMinusMarginLeft="155px"
        setIsOpen={setSCSOpen}
        subNavs={pages.scs}
      />
      <Nav icon={<CCM />} page={pages.ccm} pathname={location.pathname} />
      <Nav icon={<SLS />} page={pages.sls} pathname={location.pathname} />
      <Nav icon={<StoreServices />} page={pages.storeServices} pathname={location.pathname} />
      <Nav icon={<StoreHierarchy />} page={pages.storeHierarchy} pathname={location.pathname} />
      <Nav icon={<TaxIdentifiers />} page={pages.taxIdentifiers} pathname={location.pathname} />
      <Nav icon={<Traffic />} page={pages.traffic} pathname={location.pathname} />
      <Nav icon={<Zones />} page={pages.zones} pathname={location.pathname} />
    </nav>
  );
};

SideBar.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default SideBar;
